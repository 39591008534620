<template>
  <div class="container-xxl">
    <div class="row pt-3">
      <div class="col"></div>
      <div class="col-5 text-center">
        <br/>
        <br/>
        <h1><strong>The most powerful coaching tools at your fingertips</strong></h1>
        <div v-if="days > 0 && status != `canceled`">
          <p>You have {{ days }} days till your trial expires.</p>
        </div>
        <div v-if="status == `canceled`">
          <p>Your subscription was cancelled. Please subscribe to resume.</p>
        </div>
      </div>
      <div class="col"></div>
    </div>
    <div class="row pt-3 justify-content-center">
      <!-- <div class="col-3 mt-3 pt-4">
        <div class="panel text-center shadow">
          <div class="panel-hdr bg-grey border p-1 rounded-3 ">
            <h4 class="mt-4">The Standard Plan</h4>
            <h1>$64.99/month</h1>
            <p>Billed monthly</p>
          </div>
          <div class="panel-content border p-1 bg-white rounded-3">
            <br/>
              <br/>
              <h3>Up to 20 active clients</h3>
              <br/>
              <p class="mb-0">Adherence Tracker</p>
              <p class="mb-0">Custom Metric Tracker</p>
              <p class="mb-0">Free Client iOS App</p>
              <p class="mb-0">Personalised Program Builder</p>
              <p class="mb-0">Video Feedback</p>
              <p class="mb-0">Custom Video Library</p>
              <p class="mb-0">BMR Calculator</p>
              <p class="mb-0">Caloric Guidance</p>
              <br>
              <SubmitButton
                @click="fetchCheckoutPortal4"
                :icon="'credit-card'"
                :text="'Upgrade to Pro'"
                :isLoading="isFetchingCheckoutUrl2"
                class="btn btn-sm btn-outline-dark mt-2"
              ></SubmitButton>
              <br/>
              <br/>
          </div>
        </div>
      </div> -->
      <div class="col-4 mt-0">
        <!-- <button type="button"
          id="recommend" class="btn" disabled>Recommended</button> -->
        <div class="panel text-center shadow">
          <div class="panel-hdr bg-grey border p-1 rounded-3 ">
            <h4 class="mt-4">Monthly Subscription</h4>
            <h1>$9.99/month</h1>
            <p>Billed monthly</p>
          </div>
          <div class="panel-content border p-1 bg-white rounded-3">
              <br/>
              <br/>
              <h3>Train up to 30 clients</h3>
              <br/>
              <p class="mb-0">Adherence Tracker</p>
              <p class="mb-0">Custom Metric Tracker</p>
              <p class="mb-0">Free Client iOS App</p>
              <p class="mb-0">Personalised Program Builder</p>
              <p class="mb-0">Video Feedback</p>
              <p class="mb-0">Custom Video Library</p>
              <p class="mb-0">BMR Calculator</p>
              <p class="mb-0">Caloric Guidance</p>
              <br/>
              <br/>
              <SubmitButton
                @click="fetchCheckoutPortal1"
                v-if="this.status === 'canceled'
                || this.noPlan === true "
                :icon="'credit-card'"
                :text="'Subscribe Now'"
                :isLoading="isFetchingCheckoutUrl1"
                class="btn btn-primary mt-2"
              ></SubmitButton>
              <SubmitButton
                @click="fetchBillingPortal"
                v-if="this.status === 'trialing'"
                :icon="'credit-card'"
                :text="'Manage your Starter Plan'"
                :isLoading="isFetchingBillingUrlPlan"
                class="btn btn-primary mt-2"
              ></SubmitButton>
               <br/>
               <br/>
          </div>
        </div>
      </div>
       <div class="col-4 mt-0 ">
        <div class="panel text-center shadow">
          <div class="panel-hdr bg-grey border p-1 rounded-3 ">
            <h4 class="mt-4">Annual Subscription</h4>
            <h1>$95.99/month</h1>
            <p>Billed annually</p>
          </div>
          <div class="panel-content border p-1 bg-white rounded-3">
              <br>
              <br>
              <h3>Train up to 50 clients</h3>
              <br/>
              <p class="mb-0">Adherence Tracker</p>
              <p class="mb-0">Custom Metric Tracker</p>
              <p class="mb-0">Free Client iOS App</p>
              <p class="mb-0">Personalised Program Builder</p>
              <p class="mb-0">Video Feedback</p>
              <p class="mb-0">Custom Video Library</p>
              <p class="mb-0">BMR Calculator</p>
              <p class="mb-0">Caloric Guidance</p>
              <br>
              <br/>
              <SubmitButton
                @click="fetchCheckoutPortal2"
                v-if="this.status === 'canceled'
                || this.noPlan === true "
                :icon="'credit-card'"
                :text="'Subscribe Now'"
                :isLoading="isFetchingCheckoutUrl1"
                class="btn btn-primary mt-2"
              ></SubmitButton>
              <SubmitButton
                @click="fetchBillingPortal"
                v-if="this.status === 'trialing'"
                :icon="'credit-card'"
                :text="'Manage your Starter Plan'"
                :isLoading="isFetchingBillingUrlPlan"
                class="btn btn-primary mt-2"
              ></SubmitButton>
              <br>
              <br>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3 text-center">
      <div class="col">
        <br/>
        <br/>
        <p>More than 50 clients? Email
          <a class="text-primary" href="mailto:admin@trainifier.co">admin@trainifier.co</a>
           for special pricing.</p>
      </div>
    </div>
 </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { httpGet } from '@/core/http';

export default {
  name: 'Activate',
  components: {
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapGetters('auth', ['authUserIsCoach']),
    ...mapGetters('auth', ['authUserIsAdmin', 'authUserIsCoach', 'authUserIsSubscriptionPlan']),
    checkoutStatus() {
      return this.$route.query.status;
    },
  },
  methods: {
    async fetchInfo() {
      try {
        this.isLoading = true;
        const { data } = await httpGet('/subscription');
        this.plan = data;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchExpiryInfo() {
      if (!this.authUserIsCoach) return;
      try {
        this.isLoading = true;
        this.noPlan = false;
        const { data } = await httpGet('/subscription/stripeSubscriptionExpiry');
        const dayss = data[0];
        this.days = dayss;
        const status = data[1];
        this.status = status;
        switch (status) {
          case ('canceled'):
            this.dropdownDays = 'Please activate a subscription.';
            break;
          case ('trialing'):
            this.dropdownDays = `Your trial expires in ${dayss} days`;
            break;
          default:
            this.dropdownDays = '';
            break;
        }
      } catch (err) {
        // this.$store.dispatch('addSystemError', err, { root: true });
        this.noPlan = true;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCheckoutPortal5() {
      this.isFetchingCheckoutUrl3 = true;
      try {
        const { data: { url } } = await httpGet('/subscription/billingurl');
        // const url = await httpGet('/subscription/billingurl', { planid: 5 });
        console.log('The returned url was ');
        console.log(url.data);
        window.location.href = url;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isFetchingCheckoutUrl3 = false;
      }
    },
    async fetchCheckoutPortal1() {
      this.isFetchingCheckoutUrl1 = true;
      try {
        if (this.authUserIsCoach && (this.status === 'canceled' || this.noPlan === true)) {
          // start a new plan
          // const { data: { url } } = await httpGet('/subscription/existingUserNewPlan');
          const { data: { url } } = await httpGet('/subscription/existingUserNewPlan', { planid: 6 });
          window.location.href = url;
        } else {
          const { data: { url } } = await httpGet('/subscription/billingurl');
          // const { data: { url } } = await httpGet('/subscription/billingurl', { planid: 6 });
          // const url = await httpGet('/subscription/billingurl', { planid: 5 });
          console.log('The returned url was ');
          console.log(url.data);
          window.location.href = url;
        }
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isFetchingCheckoutUrl1 = false;
      }
    },
    async fetchCheckoutPortal2() {
      this.isFetchingCheckoutUrl2 = true;
      try {
        if (this.authUserIsCoach && (this.status === 'canceled' || this.noPlan === true)) {
          // start a new plan
          // const { data: { url } } = await httpGet('/subscription/existingUserNewPlan');
          const { data: { url } } = await httpGet('/subscription/existingUserNewPlan', { planid: 7 });
          window.location.href = url;
        } else {
          const { data: { url } } = await httpGet('/subscription/billingurl');
          // const { data: { url } } = await httpGet('/subscription/billingurl', { planid: 6 });
          // const url = await httpGet('/subscription/billingurl', { planid: 5 });
          console.log('The returned url was ');
          console.log(url.data);
          window.location.href = url;
        }
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isFetchingCheckoutUrl2 = false;
      }
    },
    async fetchBillingPortal() {
      if (!this.authUserIsCoach) return;
      this.isFetchingBillingUrlPlan = true;
      this.noPlan = false;
      try {
        const { data: { url } } = await httpGet('/subscription/billingurl');
        window.location.href = url;
        // this.billingUrl = url;
        console.log(url);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isFetchingBillingUrlPlan = false;
      }
    },
  },
  data() {
    return {
      days: null,
      status: null,
      dropdownDays: null,
      isFetchingBillingUrl: false,
      isFetchingCheckoutUrl1: false,
      isFetchingCheckoutUrl2: false,
      isFetchingCheckoutUrl3: false,
      isFetchingBillingUrlPlan: false,
      isSubmitting: false,
      isSubmitted: false,
      plan: null,
      noPlan: false,
      form: {
        plan: '',
      },
    };
  },
  mounted() {
    this.fetchExpiryInfo();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars.scss';

.nav-header-container {
  z-index: 1105;
}
.panel-hdr{
  background-color: #f5f5f7;
}

#recommend {
  position:absolute;
  margin-top:-20px;
  margin-left:100px;
  z-index: 1;
  width: 140px;
  height: 40px;
  text-align:center;
  color: white;
  background-color: #fc4201;
  opacity: 1 !important;
}

@media screen and (max-width: 1400px) {

#recommend {
  display: none;
}
}

.rounded{
  border-radius: 1em !important;
}

.rounded-top{
  border-radius: 1em !important;
}

.rounded-bottom{
  border-radius: 1em !important;
}

.nav-header {
  position: relative;
  padding-right: 2.25rem;

  &::before, &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 1.5rem;
    background-color: $borderColor;
    pointer-events: none;
  }

  &::before {
    bottom: 50%;
    transform-origin: 100% 100%;
    transform: rotate(-30deg);
  }

  &::after {
    top: 50%;
    transform-origin: 100% 0%;
    transform: rotate(30deg);
  }
}
</style>
