var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-xxl"},[_c('div',{staticClass:"row pt-3"},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col-5 text-center"},[_c('br'),_c('br'),_vm._m(0),(_vm.days > 0 && _vm.status != `canceled`)?_c('div',[_c('p',[_vm._v("You have "+_vm._s(_vm.days)+" days till your trial expires.")])]):_vm._e(),(_vm.status == `canceled`)?_c('div',[_c('p',[_vm._v("Your subscription was cancelled. Please subscribe to resume.")])]):_vm._e()]),_c('div',{staticClass:"col"})]),_c('div',{staticClass:"row pt-3 justify-content-center"},[_c('div',{staticClass:"col-4 mt-0"},[_c('div',{staticClass:"panel text-center shadow"},[_vm._m(1),_c('div',{staticClass:"panel-content border p-1 bg-white rounded-3"},[_c('br'),_c('br'),_c('h3',[_vm._v("Train up to 30 clients")]),_c('br'),_c('p',{staticClass:"mb-0"},[_vm._v("Adherence Tracker")]),_c('p',{staticClass:"mb-0"},[_vm._v("Custom Metric Tracker")]),_c('p',{staticClass:"mb-0"},[_vm._v("Free Client iOS App")]),_c('p',{staticClass:"mb-0"},[_vm._v("Personalised Program Builder")]),_c('p',{staticClass:"mb-0"},[_vm._v("Video Feedback")]),_c('p',{staticClass:"mb-0"},[_vm._v("Custom Video Library")]),_c('p',{staticClass:"mb-0"},[_vm._v("BMR Calculator")]),_c('p',{staticClass:"mb-0"},[_vm._v("Caloric Guidance")]),_c('br'),_c('br'),(this.status === 'canceled'
               || this.noPlan === true )?_c('SubmitButton',{staticClass:"btn btn-primary mt-2",attrs:{"icon":'credit-card',"text":'Subscribe Now',"isLoading":_vm.isFetchingCheckoutUrl1},on:{"click":_vm.fetchCheckoutPortal1}}):_vm._e(),(this.status === 'trialing')?_c('SubmitButton',{staticClass:"btn btn-primary mt-2",attrs:{"icon":'credit-card',"text":'Manage your Starter Plan',"isLoading":_vm.isFetchingBillingUrlPlan},on:{"click":_vm.fetchBillingPortal}}):_vm._e(),_c('br'),_c('br')],1)])]),_c('div',{staticClass:"col-4 mt-0"},[_c('div',{staticClass:"panel text-center shadow"},[_vm._m(2),_c('div',{staticClass:"panel-content border p-1 bg-white rounded-3"},[_c('br'),_c('br'),_c('h3',[_vm._v("Train up to 50 clients")]),_c('br'),_c('p',{staticClass:"mb-0"},[_vm._v("Adherence Tracker")]),_c('p',{staticClass:"mb-0"},[_vm._v("Custom Metric Tracker")]),_c('p',{staticClass:"mb-0"},[_vm._v("Free Client iOS App")]),_c('p',{staticClass:"mb-0"},[_vm._v("Personalised Program Builder")]),_c('p',{staticClass:"mb-0"},[_vm._v("Video Feedback")]),_c('p',{staticClass:"mb-0"},[_vm._v("Custom Video Library")]),_c('p',{staticClass:"mb-0"},[_vm._v("BMR Calculator")]),_c('p',{staticClass:"mb-0"},[_vm._v("Caloric Guidance")]),_c('br'),_c('br'),(this.status === 'canceled'
               || this.noPlan === true )?_c('SubmitButton',{staticClass:"btn btn-primary mt-2",attrs:{"icon":'credit-card',"text":'Subscribe Now',"isLoading":_vm.isFetchingCheckoutUrl1},on:{"click":_vm.fetchCheckoutPortal2}}):_vm._e(),(this.status === 'trialing')?_c('SubmitButton',{staticClass:"btn btn-primary mt-2",attrs:{"icon":'credit-card',"text":'Manage your Starter Plan',"isLoading":_vm.isFetchingBillingUrlPlan},on:{"click":_vm.fetchBillingPortal}}):_vm._e(),_c('br'),_c('br')],1)])])]),_vm._m(3)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('strong',[_vm._v("The most powerful coaching tools at your fingertips")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-hdr bg-grey border p-1 rounded-3"},[_c('h4',{staticClass:"mt-4"},[_vm._v("Monthly Subscription")]),_c('h1',[_vm._v("$9.99/month")]),_c('p',[_vm._v("Billed monthly")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-hdr bg-grey border p-1 rounded-3"},[_c('h4',{staticClass:"mt-4"},[_vm._v("Annual Subscription")]),_c('h1',[_vm._v("$95.99/month")]),_c('p',[_vm._v("Billed annually")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pt-3 text-center"},[_c('div',{staticClass:"col"},[_c('br'),_c('br'),_c('p',[_vm._v("More than 50 clients? Email "),_c('a',{staticClass:"text-primary",attrs:{"href":"mailto:admin@trainifier.co"}},[_vm._v("admin@trainifier.co")]),_vm._v(" for special pricing.")])])])
}]

export { render, staticRenderFns }